.centeredMenuContainer {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #3337;
    backdrop-filter: blur(10px);
}

.joinMenu {
    margin: 50px auto 0 auto;
    max-width: 300px;
    width: 100%;
    padding: 20px;
}

.menuInput,
.menuButton {
    width: calc(100% - 5px);
    border-radius: 20px;
    padding: 10px;
    background-color: #333;
    border: 3px solid #999;
    box-shadow: 0 0 15px #000;
    outline: none;
    color: white;
    text-align: center;
    font-family: sans-serif;
    font-size: 21px;
    margin: 10px 0;
    transition-property: background-color;
    transition-duration: 0.2s;
    box-sizing: border-box;
}

.menuButton {
    cursor: pointer;
}

.menuInput:hover,
.menuButton:hover {
    background-color: #444;
}

/* STATS */

.stats,
.toplist {
    font-family: sans-serif;
    position: fixed;
    z-index: 5;
    top: 10px;
    left: 10px;
    border-radius: 30px 10px 10px 10px;
    background-color: #fff9;
    padding-top: 20px;
    box-shadow: 0 0 15px #000;
    min-width: 150px;
    backdrop-filter: saturate(200%) blur(5px);
}

.stats .statElement {
    margin: 10px;
    padding-left: 5px;
    margin-left: 5px;
    border-left: 3px solid #35f;
}

.stats .statElementName {
    font-size: 20px;
    color: #111;
    overflow: auto;
    font-weight: bold;
}

.stats .statElementName::after {
    content: ":";
}

.stats .statElementValue {
    font-size: 18px;
    color: #333;
}

/* TOPLIST */

.toplist {
    top: 10px;
    left: auto;
    right: 10px;
    border-radius: 10px 30px 10px 10px;
    padding-top: 5px;
}

.toplist .title {
    text-align: center;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
}

.toplist .items {
    margin-bottom: 10px;
}

.toplist .item {}

.toplist .item td {
    padding: 5px;
}

.toplist .item .number {
    text-align: center;
    color: #400;
}

.toplist .item .number::after {
    content: ".";
}

.toplist .item .name {
    min-width: 120px;
}

.toplist .item .score {
    min-width: 30px;
    text-align: center;
    color: #037;
}

#touchJoystick {
    position: fixed;
    z-index: 100;
    width: 150px;
    height: 150px;
    pointer-events: none;
    border: 5px solid white;
    border-radius: 50%;
    background-color: #fff5;
    backdrop-filter: blur(3px);
    margin-left: -75px;
    margin-top: -75px;
    left: 0;
    top: 0;
    display: none;
}

#touchJoystick .stick {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fff;
    margin-left: 50px;
    margin-top: 50px;
    top: 0;
    left: 0;
}

.navButton {
    position: fixed;
    z-index: 10;
    bottom: 10px;
    left: 10px;
    width: 50px;
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    transition-property: margin-left, margin-bottom, width, height;
    transition-duration: 0.2s;
}

.navButton:hover {
    width: 60px;
    height: 60px;
    margin-left: -5px;
    margin-bottom: -5px;
}

#fullscreenButton {
    left: 75px;
    background-image: url(./img/buttons/fullscreen.svg);
}

#menuButton {
    background-image: url(./img/buttons/menu.svg);
}

@media only screen and (max-width: 500px),
only screen and (max-height: 600px) {

    .joinMenu {
        margin-top: 0px;
    }

    /* Stats */
    .stats,
    .toplist {
        min-width: 100px;
    }

    .stats .statElement {
        margin: 5px;
        padding-left: 3px;
        margin-left: 3px;
        border-left: 2px solid #35f;
    }

    .stats .statElementName {
        font-size: 13px;
    }

    .stats .statElementValue {
        font-size: 12px;
    }

    /* Toplist */
    .toplist .title {
        font-size: 15px;
    }

    .toplist .item td {
        padding: 2px;
        font-size: 12px;
    }

    .toplist .item .name {
        min-width: 80px;
    }
}